import { parseISO } from 'date-fns';
import { format } from 'date-fns-tz';

export default class DateUtils {
  static format(date: any): any {
    return format(parseISO(date), 'dd/MM/yyyy', {
      timeZone: 'America/Sao_Paulo'
    });
  }

  static formatWithHour(date: any): string {
    return format(parseISO(date), 'dd/MM/yyyy - HH:mm', {
      timeZone: 'America/Sao_Paulo'
    });
  }

  static formatStringToDate(value: string): string {
    const date = value.split('-');

    if (date.length < 3) {
      return '';
    }

    return `${date[2]}/${date[1]}/${date[0]}`;
  }
}
