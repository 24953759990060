import React from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect
} from 'react-router-dom';

import LayoutDefault from '../layouts/default';
import ScrollToTop from '../shared/components/ScrollToTop';
import { useAuth } from '../shared/hooks/useAuth';

// import { useAuth } from '../hooks/auth';

interface IRouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<IRouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  //const { user } = useAuth();

  const user = true;

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        if (isPrivate && !!user === false) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location }
              }}
            />
          );
        }

        if (!isPrivate && !!user === true) {
          return (
            <Redirect
              to={{
                pathname: '/dashboard',
                state: { from: location }
              }}
            />
          );
        } 

        if (!user) {
          return <Component />;
        }

        return (
          <LayoutDefault>
            <ScrollToTop />
            <Component />
          </LayoutDefault>
        );
      }}
    />
  );
};

export default Route;
