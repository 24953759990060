import React, { useCallback } from 'react';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';

import { Flex, Box, Image, Heading, Text } from '@chakra-ui/core';
import { Form } from '@unform/web';

import logo from '../../../../assets/logo-white.png';
import Button from '../../../../components/Button';
import Input from '../../../../components/Form/Input';

const ForgotPassword: React.FC = () => {
  const history = useHistory();

  const handleBackToHome = useCallback(() => {
    history.push('/');
  }, [history]);

  return (
    <Flex width="100%" height="100vh">
      <Flex
        width="50%"
        justifyContent="center"
        alignItems="center"
        height="100%"
        color="purple.500"
      >
        <Form
          onSubmit={data => console.log(data)}
          style={{
            width: '100%',
            height: '100%',
            padding: '98px'
          }}
        >
          <Box onClick={() => handleBackToHome()}>
            <BiLeftArrowAlt size={24} />
          </Box>
          <Flex
            width="100%"
            flexDirection="column"
            fontWeight="500"
            color="purple.500"
            height="100%"
            justifyContent="center"
          >
            <Heading fontSize="24px" textAlign="center">
              Recuperar minha senha
            </Heading>

            <Input name="email" size="md" placeholder="E-mail" mt="24px" />

            <Flex justifyContent="space-between">
              <Button
                width="100%"
                fontSize="16px"
                py="8px"
                backgroundColor="purple.500"
                _hover={{
                  backgroundColor: 'pink.500'
                }}
              >
                <Text fontSize="20px">Confirmar</Text>
              </Button>
            </Flex>
          </Flex>
        </Form>
      </Flex>
      <Flex
        width="50%"
        justifyContent="center"
        alignItems="center"
        backgroundColor="purple.500"
        height="100%"
      >
        <Box px="160px">
          <Image src={logo} alt="Logo E-Florista" />
        </Box>
      </Flex>
    </Flex>
  );
};

export default ForgotPassword;
