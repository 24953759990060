import React from 'react';
import { Switch } from 'react-router-dom';

import TransactionDetailsPage from '../modules/transactions/pages/Details';
import ForgotPassword from '../modules/user/pages/ForgotPassword';
import SignIn from '../modules/user/pages/SignIn';
import SignUp from '../modules/user/pages/SignUp';
import Dashboard from '../pages/Dashboard';
import Route from './Route';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/login" exact component={SignIn} />
    <Route path="/create-account" exact component={SignUp} />
    <Route path="/forgot-password" exact component={ForgotPassword} />
    <Route
      path="/transaction/:store_id/:code"
      exact
      component={TransactionDetailsPage}
      isPrivate
    />

    <Route path="/dashboard" exact component={Dashboard} isPrivate />
  </Switch>
);

export default Routes;
