import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Flex, Box, Image, Heading, Text, PseudoBox } from '@chakra-ui/core';
import { Form } from '@unform/web';

import logo from '../../../../assets/images/logo.png';
import Button from '../../../../components/Button';
import Checkbox from '../../../../components/Form/Checkbox';
import Input from '../../../../components/Form/Input';
import Loader from '../../../../shared/components/Loader';
import { useAuth } from '../../../../shared/hooks/useAuth';

type DataSubmit = {
  email: string;
  password: string;
};

const SignIn: React.FC = () => {
  const history = useHistory();
  const { signIn } = useAuth();

  const [loading, setLoading] = useState(false);

  const handleToForgotPassword = useCallback(() => {
    history.push('/forgot-password');
  }, [history]);

  const handleSubmitLogin = useCallback(
    async (dataSubmit: DataSubmit) => {
      setLoading(true);
      const { email, password } = dataSubmit;

      await signIn({
        email,
        password
      });

      history.push('/dashboard');
    },
    [history, signIn]
  );

  return (
    <Flex width="100%" height="100vh">
      <Flex
        width="50%"
        justifyContent="center"
        alignItems="center"
        backgroundColor="purple.500"
        height="100%"
      >
        <Box px="160px">
          <Image src={logo} alt="Logo E-Florista" />
        </Box>
      </Flex>
      <Flex
        width="50%"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Form
          onSubmit={handleSubmitLogin}
          style={{
            width: '100%'
          }}
        >
          <Flex
            width="100%"
            flexDirection="column"
            p="98px"
            fontWeight="500"
            color="purple.500"
          >
            <Heading fontSize="24px" textAlign="center">
              Faça seu login
            </Heading>

            <Input name="email" size="md" placeholder="Seu email" mt="24px" />
            <Input
              name="password"
              typeInput="password"
              size="md"
              placeholder="Sua senha"
            />

            <Flex justifyContent="space-between">
              <Box width="50%">
                <Checkbox
                  name="remember_password"
                  size="sm"
                  variantColor="pink"
                >
                  Lembrar senha
                </Checkbox>

                <PseudoBox
                  onClick={() => handleToForgotPassword()}
                  _hover={{
                    cursor: 'pointer'
                  }}
                >
                  <Text fontSize="12px" color="gray.600">
                    Esqueci minha senha
                  </Text>
                </PseudoBox>
              </Box>

              <Flex width="50%" justifyContent="flex-end">
                <Button
                  type="submit"
                  width="100%"
                  maxWidth="120px"
                  fontSize="16px"
                  py="8px"
                  backgroundColor="purple.500"
                  _hover={{
                    backgroundColor: 'green.500'
                  }}
                >
                  Entrar
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Form>
      </Flex>
      {loading && <Loader />}
    </Flex>
  );
};

export default SignIn;
