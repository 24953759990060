import React from 'react';
import './styles/global.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BrowserRouter as Router } from 'react-router-dom';

import ThemeContainer from './contexts/theme/ThemeContainer';
import Routes from './routes';
import { AuthProvider } from './shared/hooks/useAuth';

const App = (): JSX.Element => {
  return (
    <ThemeContainer>
      <AuthProvider>
        <DndProvider backend={HTML5Backend}>
          <Router>
            <Routes />
          </Router>
        </DndProvider>
      </AuthProvider>
    </ThemeContainer>
  );
};

export default App;
