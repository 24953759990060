import React, { useState } from 'react';

import { Checkbox as ChakraCheckbox, CheckboxProps } from '@chakra-ui/core';

const Checkbox: React.FC<CheckboxProps> = ({
  name,
  value,
  isChecked,
  size = 'lg',
  children,
  ...rest
}) => {
  const [outline, setOutline] = useState('none');

  return (
    <ChakraCheckbox
      name={name}
      size={size}
      variantColor="green"
      value={value}
      isChecked={isChecked}
      onFocus={() => setOutline('none')}
      style={{
        outline
      }}
      {...rest}
    >
      {children}
    </ChakraCheckbox>
  );
};

export default Checkbox;
