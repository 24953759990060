export default class TextUtils {
  static maskTelephone(value: string): string {
    const mask = `(${value.substring(0, 2)}) ${value.substring(
      2,
      7
    )}-${value.substring(7, 11)}`;

    return mask;
  }

  static maskCPF(value: string): string {
    const mask = `${value.substring(0, 3)}.${value.substring(
      3,
      6
    )}.${value.substring(6, 9)}-${value.substring(9, 11)}`;

    return mask;
  }

  static convertStatusPayment(status: string) {
    switch (status) {
      case 'AGUARDANDO_COMPROVANTE':
        return 'Aguardando Comprovante';

      case 'COMPROVANTE_EM_ANALISE':
        return 'Comprovante Enviado';

      case 'AGUARDANDO_PAGAMENTO':
        return 'Aguardando Pagamento';

      case 'APROVADO':
        return 'Aprovado';

      case 'CANCELADO':
        return 'Cancelado';

      case 'EXTORNADO':
        return 'Extornado';

      default:
        return 'Negado';
    }
  }

  static convertDeliveryStatus(status: string) {
    switch (status) {
      case 'EM_PRODUCAO':
        return 'Em Produção';

      case 'AGUARDANDO_PAGAMENTO':
        return 'Aguardando Pagamento';

      case 'ENTREGUE':
        return 'Entregue';

      case 'SAIU_PARA_ENTREGA':
        return 'Saiu para Entrega';

      default:
        return 'Aguardando';
    }
  }
}
