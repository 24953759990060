import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { FiPrinter } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { Divider, Flex, Text, Image } from '@chakra-ui/core';

import { useLayout } from '../../../../layouts/default';
import { HTTP_RESPONSE } from '../../../../shared/constants';
import CurrencyUtils from '../../../../shared/utils/CurrencyUtils';
import DateUtils from '../../../../shared/utils/DateUtils';
import TextUtils from '../../../../shared/utils/TextUtils';

const TransactionDetailsPage: React.FC = () => {
  const { changeTitle } = useLayout();

  const [order, setTransaction] = useState(null);

  const { store_id, code } = useParams<{ store_id: string; code: string }>();

  const printComponentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    pageStyle: `
      *, h1, h2, h3, h4, h5, p, a {
        color: black!important; 
      }
    `
  });

  useEffect(() => {
    changeTitle(`Transação - ${code}`);

    const apiEflorista = axios.create({
      baseURL: process.env.REACT_APP_EFLORISTA_URL,
      headers: {
        store_id: `${store_id}`
      }
    });

    apiEflorista.get(`/orders/details/${code}`).then(response => {
      if (response.status === HTTP_RESPONSE.STATUS.SUCCESS) {
        setTransaction(response.data);
      }
    });
  }, [changeTitle, code, store_id]);

  return (
    <Flex width="100%" justifyContent="center">
      <Flex width="600px">
        {order && (
          <Flex
            width="100%"
            height="100%"
            backgroundColor="white"
            p="16px"
            ref={printComponentRef}
          >
            <Flex
              flexWrap="wrap"
              border="2px dashed"
              borderColor="gray.700"
              p="16px"
            >
              <Flex width="100%" justifyContent="space-between">
                <Flex width="100%" flexDirection="column" fontSize="14px">
                  <Text as="h1" color="blue.700" fontSize="16px" mb="8px">
                    {`Pedido - ${order.code}`}
                  </Text>
                  <Flex width="100%" justifyContent="space-between">
                    <Flex width="50%" flexDirection="column">
                      <Text>{`Forma de Pagamento: ${order.payment_method.name}`}</Text>
                      <Text>
                        {`Status de Pagamento: ${TextUtils.convertStatusPayment(
                          order.payment_status
                        )}`}
                      </Text>
                    </Flex>
                    <Flex width="50%">
                      <Text>
                        {`Status de Entrega: ${TextUtils.convertDeliveryStatus(
                          order.delivery_status
                        )}`}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>

              <Divider width="100%" my="16px" />

              <Flex width="100%" wrap="wrap">
                <Flex width="100%">
                  <Text color="blue.700" fontSize="16px" mb="8px">
                    Comprador
                  </Text>
                </Flex>
                <Flex width="50%" flexDirection="column" fontSize="14px">
                  <Text>{`Nome: ${order.buyer.name}`}</Text>
                  <Text>
                    {`CPF: ${TextUtils.maskCPF(order.buyer.document)}`}
                  </Text>
                </Flex>

                <Flex width="50%" flexDirection="column" fontSize="14px">
                  <Text>
                    {`Telefone: ${order.buyer.telephone}`}
                    <Text>{`E-mail: ${order.buyer.email}`}</Text>
                  </Text>
                </Flex>
              </Flex>

              <Divider width="100%" my="16px" />

              <Flex width="100%">
                <Flex width="100%" flexDirection="column" fontSize="14px">
                  <Text color="blue.700" fontSize="16px" mb="8px">
                    Dados de Entrega
                  </Text>

                  <Text>{`Cidade: ${order.delivery_address.city}`}</Text>
                  <Text>{`Bairro: ${order.delivery_address.neighborhood}`}</Text>
                  <Text>{`Rua: ${order.delivery_address.street}, ${order.delivery_address.number}`}</Text>
                  <Text>
                    {`Complemento: ${
                      order.delivery_address.complement
                        ? order.delivery_address.complement
                        : 'Não informado'
                    }`}
                  </Text>

                  <Flex mt="24px">
                    <Text mr="8px">Data de Entrega: </Text>
                    <Text>
                      {DateUtils.formatStringToDate(order.delivery_date)}
                    </Text>
                  </Flex>

                  <Flex>
                    <Text mr="8px">Hora: </Text>
                    <Text>{order.delivery_hour}</Text>
                  </Flex>

                  <Text color="black" fontSize="16px" mt="24px">
                    {`Obs: ${order.details.observations || 'Não informada'}`}
                  </Text>
                </Flex>

                <Flex width="50%" flexDirection="column" fontSize="14px">
                  <Text color="blue.700" fontSize="16px" mb="8px">
                    Dados do Destinatário
                  </Text>
                  <Text>{`Nome: ${order.details.destinatary_name}`}</Text>
                  <Text>
                    {`Telefone: ${
                      order.details.destinatary_telephone
                        ? order.details.destinatary_telephone
                        : 'Não informado'
                    }`}
                  </Text>
                </Flex>
              </Flex>

              <Divider width="100%" my="16px" />

              <Flex width="100%" flexDirection="column">
                <Flex
                  width="100%"
                  justifyContent="space-between"
                  fontSize="14px"
                >
                  <Flex width="100%" justifyContent="center" maxWidth="56px">
                    <Text>Foto</Text>
                  </Flex>
                  <Flex width="100%" justifyContent="center">
                    <Text>Nome</Text>
                  </Flex>
                  <Flex width="100%" justifyContent="center" maxWidth="80px">
                    <Text>QTD</Text>
                  </Flex>
                  <Flex width="100%" justifyContent="center" maxWidth="104px">
                    <Text>Valor</Text>
                  </Flex>
                </Flex>
                <Divider width="100%" my="4px" />

                {order.products.map(product => (
                  <Flex width="100%" flexDirection="column">
                    <Flex
                      width="100%"
                      justifyContent="space-between"
                      fontSize="12px"
                      alignItems="center"
                    >
                      <Flex
                        width="100%"
                        justifyContent="center"
                        maxWidth="56px"
                      >
                        <Image src={product.cover.thumb_url} />
                      </Flex>
                      <Flex width="100%" justifyContent="center">
                        <Text>{product.name}</Text>
                      </Flex>
                      <Flex
                        width="100%"
                        justifyContent="center"
                        maxWidth="80px"
                      >
                        <Text>{product.quantity}</Text>
                      </Flex>

                      <Flex
                        width="100%"
                        justifyContent="center"
                        maxWidth="104px"
                      >
                        <Text>
                          {product.is_promotional
                            ? CurrencyUtils.numberToCurrency(
                                Number(product.price_promotional) *
                                  Number(product.quantity)
                              )
                            : CurrencyUtils.numberToCurrency(
                                Number(product.price_sale) *
                                  Number(product.quantity)
                              )}
                        </Text>
                      </Flex>
                    </Flex>

                    <Divider width="100%" my="2px" />
                  </Flex>
                ))}
              </Flex>

              <Flex width="100%" mt="24px">
                <Flex width="100%" flexDirection="column">
                  <Text color="blue.700" fontSize="16px" mb="8px">
                    Menssagem a ser impressa no cartão
                  </Text>
                  <Text fontSize="14px">
                    {order.details.card_message
                      ? order.details.card_message
                      : 'Não informada'}
                  </Text>

                  <Flex fontSize="14px" mt="24px">
                    <Text mr="8px">Identificar remetente?</Text>
                    <Text>{order.details.identify_sender ? 'Sim' : 'Não'}</Text>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                width="100%"
                mt="24px"
                flexDirection="column"
                alignItems="flex-end"
              >
                <Flex
                  width="200px"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text fontSize="16px">Produtos:</Text>
                  <Text fontSize="18px">
                    {CurrencyUtils.numberToCurrency(order.products_value)}
                  </Text>
                </Flex>
                <Flex
                  width="200px"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text fontSize="16px">Entrega:</Text>
                  <Text fontSize="18px">
                    {CurrencyUtils.numberToCurrency(order.delivery_value)}
                  </Text>
                </Flex>
                <Flex
                  width="200px"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text fontSize="20px" mr="16px">
                    Total:
                  </Text>
                  <Text fontSize="24px">
                    {CurrencyUtils.numberToCurrency(order.total_value)}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>

      <Flex flexDirection="column" alignItems="flex-end" ml="16px">
        <Flex
          alignItems="center"
          cursor="pointer"
          onClick={() => handlePrint()}
          backgroundColor="purple.500"
          color="white"
          px="8px"
          py="4px"
        >
          <FiPrinter size={18} />
          <Text ml="8px">Imprimir</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TransactionDetailsPage;
