import React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import { Flex } from '@chakra-ui/core';

interface IProps {
  item: any[];
  row: (arg: any) => any;
  itemHeight?: number;
}
const List: React.FC<IProps> = ({ item, row, itemHeight = 48 }) => {
  return (
    <Flex height="calc(100vh - 264px)">
      <AutoSizer>
        {({ height, width }) => (
          <FixedSizeList
            width={width}
            height={height}
            itemCount={item.length}
            itemSize={itemHeight}
            itemData={item}
          >
            {row}
          </FixedSizeList>
        )}
      </AutoSizer>
    </Flex>
  );
};

export default List;
