import axios from 'axios';

import { HTTP_RESPONSE } from '../constants';

const apiGateway = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

apiGateway.interceptors.response.use(
  response => {
    // Qualquer código de status que esteja dentro de 2xx fará com que essa função seja acionada
    // Faça algo com os dados de resposta

    return response;
  },
  error => {
    if (error.response.status === HTTP_RESPONSE.STATUS.NOT_AUTORIZED) {
      localStorage.removeItem('@eflorista:token');
      localStorage.removeItem('@eflorista:user');

      window.location.href = '/login';
    }

    return error;
  }
);

export default apiGateway;
