import React, { useCallback, useState } from 'react';
import { FiVolume2, FiVolumeX } from 'react-icons/fi';

import { Flex, Box, Heading, useToast } from '@chakra-ui/core';

import { useLayout } from '../../layouts/default';

const Header: React.FC = () => {
  const { isExpandedMenu, title } = useLayout();

  const [isEnabledAudio, setIsEnabledAudio] = useState(false);

  const toast = useToast();

  const handleEnableAudio = useCallback(() => {
    const audioContext = new AudioContext();

    audioContext.resume().then(() => {
      toast({
        title: `Audio Desbloqueado`,
        status: 'info',
        description: 'O Audio das Notificações esta pronto para ser utilizado',
        duration: 5000
      });

      setIsEnabledAudio(true);
    });
  }, [toast]);

  return (
    <Flex width="100%">
      <Flex
        width={`calc(100% - ${isExpandedMenu ? 250 : 80}px)`}
        backgroundColor="white"
        borderBottom="1px solid"
        borderColor="gray.500"
        px="80px"
        py="8px"
        alignItems="center"
        justifyContent="space-between"
        position="fixed"
        zIndex={10000000}
      >
        <Box color="purple.500">
          <Heading fontSize="24px">{title}</Heading>
        </Box>
        {!isEnabledAudio && (
          <Flex
            alignItems="center"
            justifyContent="space-between"
            backgroundColor="red.500"
            py="8px"
            px="16px"
            color="white"
            cursor="pointer"
            borderColor="purple.500"
            borderRadius="4px"
            onClick={() => handleEnableAudio()}
          >
            <FiVolumeX size={24} />
          </Flex>
        )}
        {isEnabledAudio && (
          <Flex
            alignItems="center"
            justifyContent="space-between"
            backgroundColor="purple.500"
            py="8px"
            px="16px"
            color="white"
            borderRadius="4px"
          >
            <FiVolume2 size={24} />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default Header;
