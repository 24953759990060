import React, { useCallback, useState } from 'react';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';

import { BadgeProps, Flex, PseudoBox, Text } from '@chakra-ui/core';

import TextUtils from '../../shared/utils/TextUtils';

interface IProps extends BadgeProps {
  defaultStatus: any;
  orderId: string;
  storeId: string;
  transactionId: string;
  onChangeFunction?: (
    orderId: string,
    status: string,
    storeId: string,
    transactionId: string
  ) => any;
  isChangeable?: boolean;
}

const STATUS_AND_COLORS = {
  AGUARDANDO_COMPROVANTE: 'blue.500',
  COMPROVANTE_EM_ANALISE: 'green.500',
  AGUARDANDO_PAGAMENTO: 'yellow.500',
  APROVADO: 'green.500',
  CANCELADO: 'gray.800',
  EXTORNADO: 'gray.800',
  NEGADO: 'pink.500'
};

const status = [
  'AGUARDANDO_COMPROVANTE',
  'COMPROVANTE_EM_ANALISE',
  'AGUARDANDO_PAGAMENTO',
  'APROVADO',
  'CANCELADO',
  'EXTORNADO',
  'NEGADO'
];

const PaymentStatus: React.FC<IProps> = ({
  defaultStatus,
  orderId,
  storeId,
  transactionId,
  onChangeFunction = null,
  isChangeable = true,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(defaultStatus);

  const handleOnChangeStatus = useCallback(
    async (newStatus: string) => {
      setSelectedStatus(newStatus);
      setIsOpen(false);

      if (onChangeFunction) {
        await onChangeFunction(orderId, newStatus, storeId, transactionId);
      }
    },
    [onChangeFunction, orderId, storeId, transactionId]
  );

  return (
    <Flex width="100%" justifyContent="center" position="relative">
      <Flex
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        textAlign="center"
        backgroundColor={
          !isOpen ? STATUS_AND_COLORS[selectedStatus] : 'purple.500'
        }
        color="white"
        py="8px"
        px="8px"
        cursor={isChangeable ? 'pointer' : 'default'}
        {...rest}
        onClick={() => (isChangeable ? setIsOpen(!isOpen) : null)}
      >
        <Flex width="100%" justifyContent="center">
          <Text>{TextUtils.convertStatusPayment(selectedStatus)}</Text>
        </Flex>
        {isChangeable && !isOpen && <FiChevronRight />}
        {isOpen && <FiChevronDown />}
      </Flex>
      <Flex
        position="absolute"
        flexDirection="column"
        mt="32px"
        display={isOpen ? 'flex' : 'none'}
        zIndex={1000}
        backgroundColor="white"
        p="8px"
        justifyContent="space-between"
        boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
      >
        {status.map(item => (
          <PseudoBox
            key={item}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            textAlign="center"
            backgroundColor="gray.500"
            color={STATUS_AND_COLORS[item]}
            border="2px solid"
            borderColor={STATUS_AND_COLORS[item]}
            py="8px"
            px="8px"
            mb="8px"
            {...rest}
            onClick={() => handleOnChangeStatus(item)}
            cursor="pointer"
            _last={{
              mb: 0
            }}
            _hover={{
              borderColor: 'purple.500',
              color: 'purple.500'
            }}
          >
            <Text>{TextUtils.convertStatusPayment(item)}</Text>
          </PseudoBox>
        ))}
      </Flex>
    </Flex>
  );
};

export default PaymentStatus;
