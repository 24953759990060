import axios from 'axios';
import { Howl } from 'howler';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState
} from 'react';
import { useHistory } from 'react-router-dom';
import socketIOClient from 'socket.io-client';

import {
  Badge,
  Divider,
  Flex,
  StatArrow,
  StatHelpText,
  Text,
  useToast
} from '@chakra-ui/core';

import notificationSound from '../../assets/sounds/notification.wav';
import Checkbox from '../../components/Form/Checkbox';
import List from '../../components/List';
import ListEmpty from '../../components/List/ListEmpty';
import ListRow from '../../components/List/ListRow';
import PaymentStatus from '../../components/PaymentStatus';
import { useLayout } from '../../layouts/default';
import { HTTP_RESPONSE } from '../../shared/constants';
import apiGateway from '../../shared/services/apiGateway';
import CurrencyUtils from '../../shared/utils/CurrencyUtils';
import DateUtils from '../../shared/utils/DateUtils';

interface ITotalSummary {
  total_yesterday: number;
  total_today: number;
  total_last_30_days: number;
  total_last_6_months: number;
  avg_ticket: number;
}

interface ITotalSummaryVariations {
  today: {
    type: 'increase' | 'decrease' | 'equal';
    value: number;
  };
}

const Dashboard: React.FC = () => {
  const { changeTitle } = useLayout();
  const toast = useToast();
  const history = useHistory();

  const [transactions, setTransactions] = useState([]);
  const [totalSummaryVariations, setTotalSummaryVariations] = useState<
    ITotalSummaryVariations
  >(null);

  const paymentMethods = {
    CREDITCARD: 'Cartão de Crédito',
    DEPOSIT: 'Depósito',
    LINK: 'Via Link',
    PAY_IN_STORE: 'Pag. na Loja',
    PAY_IN_DELIVERY: 'Pag. na Entrega',
    PAY_IN_WAY: 'Pag. no Caminho'
  };

  const [totalSummary, setTotalSummary] = useState({
    total_yesterday: 0,
    total_today: 0,
    total_last_30_days: 0,
    total_last_6_months: 0,
    avg_ticket: 0
  } as ITotalSummary);

  const handleUpdateVariations = useCallback(
    (totalToday: number, totalYesterday: number) => {
      const variationResult = (totalToday / totalYesterday - 1) * 100;
      let type: any = 'equal';

      if (variationResult > 0) {
        type = 'increase';
      }

      if (variationResult < 0) {
        type = 'decrease';
      }

      setTotalSummaryVariations({
        today: {
          type,
          value: variationResult
        }
      });
    },
    []
  );

  const handleChangePaymentStatus = useCallback(
    async (
      code: string,
      status: string,
      store_id: string,
      transaction_id: string
    ) => {
      const apiEflorista = axios.create({
        baseURL: process.env.REACT_APP_EFLORISTA_URL,
        headers: {
          store_id: `${store_id}`
        }
      });

      await apiEflorista.put(`/orders/${code}/payment_status`, {
        status
      });

      await apiGateway
        .put(`/transactions/payment_status`, {
          status,
          order_code: code,
          store_id
        })
        .then(response => {
          if (response.status === HTTP_RESPONSE.STATUS.SUCCESS) {
            history.go(0);
          }
        });
    },
    [history]
  );

  const TransactionRow = ({ style, index, data }) => {
    return (
      <ListRow style={style} index={index}>
        <Flex
          width="100%"
          justifyContent="space-between"
          fontSize="22px"
          alignItems="center"
        >
          <Flex width="100%" justifyContent="center" maxWidth="24px">
            <Checkbox
              variantColor="green"
              size="sm"
              // onChange={e => handleChangeSelectedProductsIds(e.currentTarget)}
              value={data[index].id}
            />
          </Flex>
          <Flex
            width="100%"
            justifyContent="center"
            cursor="pointer"
            color="purple.500"
            onClick={() =>
              history.push(
                `/transaction/${data[index].store_id}/${data[index].code}`
              )
            }
          >
            <Text>{data[index].code}</Text>
          </Flex>
          <Flex width="100%" justifyContent="center">
            <Text>{data[index].store_name}</Text>
          </Flex>
          <Flex width="100%" justifyContent="center">
            <Text>{data[index].customer_name}</Text>
          </Flex>
          <Flex width="100%" justifyContent="center">
            <Text textAlign="center" lineHeight="16px">
              {CurrencyUtils.numberToCurrency(data[index].total_amount)}
            </Text>
          </Flex>

          <Flex width="100%" justifyContent="center" maxWidth="184px">
            <PaymentStatus
              defaultStatus={data[index].status}
              orderId={data[index].code}
              storeId={data[index].store_id}
              transactionId={data[index].id}
              maxWidth="172px"
              width="100%"
              fontSize="14px"
              wordBreak="initial"
              height="56px"
              fontWeight="600"
              onChangeFunction={handleChangePaymentStatus}
            />
          </Flex>

          <Flex width="100%" justifyContent="center" fontSize="16px">
            <Text textAlign="center" lineHeight="16px">
              {paymentMethods[data[index].payment_type]}
            </Text>
          </Flex>

          <Flex width="100%" justifyContent="center" fontSize="16px">
            <Text textAlign="center" lineHeight="16px">
              {DateUtils.formatWithHour(data[index].created_at)}
            </Text>
          </Flex>
        </Flex>
      </ListRow>
    );
  };

  const loadData = useCallback(() => {
    apiGateway
      .get<ITotalSummary>('/relatories/total_summary')
      .then(response => {
        if (response.status === HTTP_RESPONSE.STATUS.SUCCESS) {
          const { total_today, total_yesterday } = response.data;

          handleUpdateVariations(total_today, total_yesterday);
          setTotalSummary(response.data);
        }
      });

    apiGateway
      .get('/transactions', {
        params: {
          limit: 100
        }
      })
      .then(response => {
        if (response.status === HTTP_RESPONSE.STATUS.SUCCESS) {
          setTransactions(response.data);
        }
      });
  }, [handleUpdateVariations]);

  useLayoutEffect(() => {
    changeTitle(`Dashboard`);

    loadData();
  }, [changeTitle, handleUpdateVariations, loadData]);

  useEffect(() => {
    const sound = new Howl({
      src: [notificationSound]
    });

    const socket = socketIOClient(String(process.env.REACT_APP_IO_URL), {
      secure: true
    });

    socket.on('start', () => {
      console.log('Socket started');
    });

    socket.on('pay3', (data: any) => {
      const { transaction } = data;

      toast({
        title: `${transaction.store_name} - Pedido: ${transaction.code}`,
        status: transaction.status === 'APROVADO' ? 'success' : 'error',
        description: `Valor total: ${CurrencyUtils.numberToCurrency(
          transaction.total_amount
        )}`,
        duration: 10000
      });

      sound.play();

      loadData();
    });
  }, [history, loadData, toast]);

  return (
    <Flex width="100%" flexDirection="column">
      <Flex width="100%" justifyContent="space-between">
        <Flex
          width="250px"
          height="100%"
          backgroundColor="white"
          p="16px"
          flexDirection="column"
        >
          <Flex width="100%" justifyContent="space-between" flexWrap="nowrap">
            <Text color="gray.800" fontSize="16px" fontWeight="500">
              Total Hoje
            </Text>
            <Badge
              display="flex"
              alignItems="center"
              backgroundColor="green.500"
              color="white"
              px="8px"
            >
              <Text>Bruto</Text>
            </Badge>
          </Flex>
          <Flex
            width="100%"
            alignItems="center"
            justifyContent="space-between"
            mt="8px"
          >
            <Text color="green.500" fontSize="24px" mt="auto" mr="16px">
              {CurrencyUtils.numberToCurrency(totalSummary.total_today)}
            </Text>

            {totalSummary.total_yesterday !== 0 && (
              <StatHelpText>
                {totalSummaryVariations.today.type !== 'equal' && (
                  <StatArrow type={totalSummaryVariations.today.type} />
                )}
                {`${totalSummaryVariations.today.value.toFixed(2)}%`}
              </StatHelpText>
            )}
          </Flex>
          <Divider />
        </Flex>

        <Flex
          width="250px"
          height="100%"
          backgroundColor="white"
          p="16px"
          flexDirection="column"
        >
          <Flex width="100%" justifyContent="space-between">
            <Text color="gray.800" fontSize="16px" fontWeight="500">
              Total Ontem
            </Text>
            <Badge
              display="flex"
              alignItems="center"
              backgroundColor="green.500"
              color="white"
              px="8px"
            >
              <Text>Bruto</Text>
            </Badge>
          </Flex>
          <Flex
            width="100%"
            alignItems="center"
            justifyContent="space-between"
            mt="8px"
          >
            <Text color="green.500" fontSize="24px" mt="auto" mr="16px">
              {CurrencyUtils.numberToCurrency(totalSummary.total_yesterday)}
            </Text>
          </Flex>
          <Divider />
        </Flex>

        <Flex
          width="250px"
          height="100%"
          backgroundColor="white"
          p="16px"
          flexDirection="column"
        >
          <Flex width="100%" justifyContent="space-between">
            <Text color="gray.800" fontSize="16px" fontWeight="500">
              Total 30 dias
            </Text>
            <Badge
              display="flex"
              alignItems="center"
              backgroundColor="green.500"
              color="white"
              px="8px"
            >
              <Text>Bruto</Text>
            </Badge>
          </Flex>

          <Flex
            width="100%"
            alignItems="center"
            justifyContent="space-between"
            mt="8px"
          >
            <Text color="green.500" fontSize="24px" mt="auto" mr="16px">
              {CurrencyUtils.numberToCurrency(totalSummary.total_last_30_days)}
            </Text>
          </Flex>

          <Divider my="4px" />
        </Flex>

        <Flex
          width="250px"
          height="100%"
          backgroundColor="white"
          p="16px"
          flexDirection="column"
        >
          <Flex width="100%" justifyContent="space-between">
            <Text color="gray.800" fontSize="16px" fontWeight="500">
              Total de 6 Meses
            </Text>
            <Badge
              display="flex"
              alignItems="center"
              backgroundColor="green.500"
              color="white"
              px="8px"
            >
              <Text>Bruto</Text>
            </Badge>
          </Flex>
          <Flex
            width="100%"
            alignItems="center"
            justifyContent="space-between"
            mt="8px"
          >
            <Text color="green.500" fontSize="24px" mt="auto" mr="16px">
              {CurrencyUtils.numberToCurrency(totalSummary.total_last_6_months)}
            </Text>
          </Flex>
          <Divider />
        </Flex>

        <Flex
          width="250px"
          height="100%"
          backgroundColor="white"
          p="16px"
          flexDirection="column"
        >
          <Flex width="100%" justifyContent="space-between" flexWrap="nowrap">
            <Text color="gray.800" fontSize="16px" fontWeight="500">
              Ticket médio
            </Text>
            <Badge
              display="flex"
              alignItems="center"
              backgroundColor="green.500"
              color="white"
              px="8px"
            >
              <Text>Bruto</Text>
            </Badge>
          </Flex>
          <Flex
            width="100%"
            alignItems="center"
            justifyContent="space-between"
            mt="8px"
          >
            <Text color="green.500" fontSize="24px" mt="auto" mr="16px">
              {CurrencyUtils.numberToCurrency(totalSummary.avg_ticket)}
            </Text>
          </Flex>
          <Divider />
        </Flex>
      </Flex>

      <Flex
        width="100%"
        backgroundColor="white"
        mt="24px"
        px="24px"
        py="16px"
        flexDirection="column"
      >
        <Flex width="100%" flexDirection="column">
          <Text mr="16px" fontSize="24px" color="purple.500">
            Transações Recentes
          </Text>

          <Divider my="4px" />
        </Flex>

        <Flex
          width="100%"
          py="8px"
          justifyContent="space-between"
          fontSize="14px"
          maxWidth="100%"
          pr="16px"
        >
          <Flex width="100%" justifyContent="center" maxWidth="24px">
            <Text>#</Text>
          </Flex>

          <Flex width="100%" justifyContent="center">
            <Text>Pedido</Text>
          </Flex>

          <Flex width="100%" justifyContent="center">
            <Text>Loja</Text>
          </Flex>

          <Flex width="100%" justifyContent="center">
            <Text>Comprador</Text>
          </Flex>

          <Flex width="100%" justifyContent="center">
            <Text>Total</Text>
          </Flex>

          <Flex width="100%" justifyContent="center" minWidth="184px">
            <Text>Status</Text>
          </Flex>

          <Flex width="100%" justifyContent="center">
            <Text>Pagamento</Text>
          </Flex>

          <Flex width="100%" justifyContent="center">
            <Text>Data - Hora</Text>
          </Flex>
        </Flex>
        <Divider my="0px" />

        {transactions.length > 0 && (
          <List item={transactions} row={TransactionRow} itemHeight={90} />
        )}

        {transactions.length <= 0 && <ListEmpty />}
      </Flex>
    </Flex>
  );
};

export default Dashboard;
