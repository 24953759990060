import React from 'react';
import { FiAlertCircle } from 'react-icons/fi';

import { Flex } from '@chakra-ui/core';

import Error from './styles';

interface IProps {
  error: string;
  color: string;
}

const InputValidator: React.FC<IProps> = ({ error, color = 'red' }) => (
  <>
    <Flex
      display={['none', 'none', 'flex']}
      position="absolute"
      right="0"
      height="100%"
      alignItems="center"
      justifyContent="center"
      mx="8px"
      textAlign="center"
      flexWrap="wrap"
    >
      <Error title={error}>
        <FiAlertCircle color={color} size={16} />
      </Error>
    </Flex>
  </>
);

export default InputValidator;
