import React from 'react';
import Lottie from 'react-lottie';

import { Flex, Text } from '@chakra-ui/core';

import animationData from '../../assets/lotties/loader.json';

interface IProps {
  text?: string;
}

const Loader: React.FC<IProps> = ({ text = null }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Flex
      width="100%"
      height="100vh"
      position="absolute"
      zIndex={10000}
      top="0"
      left="0"
      alignItems="center"
      justifyContent="center"
      backgroundColor="white"
      flexDirection="column"
    >
      <Lottie
        isClickToPauseDisabled
        options={defaultOptions}
        height={96}
        style={{
          maxWidth: 96
        }}
      />
      {text && (
        <Text mt="16px" fontWeight="500" fontSize="16px" color="black">
          {text}
        </Text>
      )}
    </Flex>
  );
};

export default Loader;
