import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Flex, Box, Image, Heading } from '@chakra-ui/core';
import { Form } from '@unform/web';

import logo from '../../../../assets/logo-white.png';
import Button from '../../../../components/Button';
import Input from '../../../../components/Form/Input';
import Loader from '../../../../shared/components/Loader';
import { HTTP_RESPONSE } from '../../../../shared/constants';
import { useAuth } from '../../../../shared/hooks/useAuth';
import apiGateway from '../../../../shared/services/apiGateway';

type DataSubmit = {
  store_name: string;
  user_email: string;
  user_password: string;
  type: 'STORE' | 'FLORICULTURE';
};

const SignUp: React.FC = () => {
  const history = useHistory();
  const { signIn } = useAuth();

  const [loading, setLoading] = useState(false);

  const handleSubmitLogin = useCallback(
    async (dataSubmit: DataSubmit) => {
      const response = await apiGateway.post('/stores', dataSubmit);

      if (response.status === HTTP_RESPONSE.STATUS.CREATED) {
        setLoading(true);

        setTimeout(async () => {
          const { user_email, user_password } = dataSubmit;

          await signIn({
            email: user_email,
            password: user_password
          });

          history.push('/dashboard');
        }, 5000);
      }
    },
    [history, signIn]
  );

  return (
    <Flex
      width="100%"
      height="100vh"
      position="relative"
      flexDirection="column"
    >
      <Flex width="100%" height="100%">
        <Flex
          width="40%"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Form
            onSubmit={handleSubmitLogin}
            style={{
              width: '100%'
            }}
          >
            <Flex
              width="100%"
              flexDirection="column"
              p="98px"
              fontWeight="500"
              color="purple.500"
            >
              <Heading fontSize="24px" textAlign="center">
                Crie sua floricultura virtual
              </Heading>

              <Input
                name="store_name"
                size="md"
                placeholder="Nome da sua floricultura"
                mt="24px"
              />

              <Input name="user_email" size="md" placeholder="Seu email" />

              <Input
                name="user_password"
                size="md"
                placeholder="Sua senha"
                typeInput="password"
              />

              <Input
                name="password_confirmation"
                size="md"
                placeholder="Confirmar senha"
                typeInput="password"
              />

              <Input name="type" hidden value="FLORICULTURE" />

              <Flex justifyContent="space-between" mt="24px">
                <Flex width="100%" justifyContent="flex-end">
                  <Button
                    type="submit"
                    width="100%"
                    fontSize="20px"
                    py="16px"
                    backgroundColor="purple.500"
                    _hover={{
                      backgroundColor: 'green.500'
                    }}
                  >
                    Criar Floricultura Virtual
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Form>
        </Flex>

        <Flex
          width="60%"
          justifyContent="center"
          alignItems="center"
          backgroundColor="purple.500"
          height="100%"
        >
          <Box px="160px">
            <Image src={logo} alt="Logo E-Florista" />
          </Box>
        </Flex>
      </Flex>
      {loading && (
        <Loader text="Sua loja está sendo criada, aguarde um estantes..." />
      )}
    </Flex>
  );
};

export default SignUp;
