import { motion, Variants } from 'framer-motion';
import React, { useCallback, useEffect, useState } from 'react';
import {
  FiGrid,
  FiSettings,
  FiAlignJustify,
  FiArrowLeft
} from 'react-icons/fi';
import { RiArrowLeftRightLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';

import { Flex, Image, Text, PseudoBox, Badge } from '@chakra-ui/core';

import logo from '../../assets/images/logo.png';
import { useLayout } from '../../layouts/default';

const SideMenu: React.FC = () => {
  const history = useHistory();
  const { isExpandedMenu, collapseOrExpand } = useLayout();
  const [isMenuSettings, setMenuSettings] = useState(false);

  const handleChangeMenu = useCallback(() => {
    setMenuSettings(oldState => !oldState);
  }, []);

  const [options, setOptions] = useState([]);

  const variantsMenu: Variants = {
    expanded: {
      width: '250px'
    },
    collapsed: {
      width: '80px'
    }
  };

  const variantsOptions: Variants = {
    expanded: {
      marginLeft: '24px'
    },
    collapsed: {
      marginLeft: '24px'
    }
  };

  const variantsOptionsText: Variants = {
    expanded: {
      opacity: 1,
      x: 0
    },
    collapsed: {
      opacity: 0,
      x: '-200%'
    }
  };

  useEffect(() => {
    setOptions(() => {
      if (isMenuSettings) {
        return [];
      }

      return [
        {
          name: 'Dashboard',
          route: '/dashboard',
          icon: <FiGrid size={24} />
        },
        {
          name: 'Transações',
          route: '/banners',
          icon: <RiArrowLeftRightLine size={24} />
        }
      ];
    });
  }, [isMenuSettings]);

  return (
    <Flex
      height="100%"
      backgroundColor="gray.900"
      flexDirection="column"
      color="white"
      top="0"
      left="0"
      position="fixed"
      zIndex={100000000}
    >
      <PseudoBox
        color="purple.500"
        borderRadius="2px"
        position="absolute"
        top="12px"
        display="flex"
        alignItems="flex-start"
        height="100%"
        right="-48px"
        cursor="pointer"
        onClick={() => collapseOrExpand()}
      >
        {isExpandedMenu && <FiAlignJustify size={24} />}
        {!isExpandedMenu && <FiAlignJustify size={24} />}
      </PseudoBox>
      <motion.div
        style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
        initial="expanded"
        animate={isExpandedMenu ? 'expanded' : 'collapsed'}
        variants={variantsMenu}
      >
        <Flex width="100%" flexDirection="column">
          <Flex
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
            py="24px"
          >
            {isExpandedMenu && <Image src={logo} height="80px" />}
            {!isExpandedMenu && <Image src={logo} height="40px" />}
          </Flex>
        </Flex>
        <Flex
          width="100%"
          backgroundColor="white"
          color="green.500"
          py="4px"
          boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
        >
          {isExpandedMenu && (
            <Flex width="100%" justifyContent="center" alignItems="center">
              <Text fontSize="16px" mr="8px">
                Versão Beta
              </Text>
              <Badge
                backgroundColor="green.500"
                color="white"
                fontWeight="500"
                fontSize="14px"
                px="8px"
              >
                1.0.0
              </Badge>
              {/* <FiArrowDown size={18} /> */}
            </Flex>
          )}
        </Flex>
        <Flex
          width="100%"
          height="100%"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          {options.map(option => (
            <Flex
              key={option.name}
              width="100%"
              flexDirection="column"
              py="4px"
            >
              <PseudoBox
                display="flex"
                width="100%"
                onClick={() => {
                  if (option.is_parent || option?.iscoming) {
                    return undefined;
                  }

                  return history.push(option.route);
                }}
                alignItems="center"
                cursor={
                  option.is_parent || option?.iscoming ? 'default' : 'pointer'
                }
                _hover={{
                  color:
                    option.is_parent || option?.iscoming ? 'white' : 'green.500'
                }}
              >
                <motion.div
                  style={{
                    display: 'flex'
                  }}
                  initial="expanded"
                  animate={isExpandedMenu ? 'expanded' : 'collapsed'}
                  variants={variantsOptions}
                >
                  {option.icon}
                </motion.div>

                <motion.div
                  initial="expanded"
                  animate={isExpandedMenu ? 'expanded' : 'collapsed'}
                  variants={variantsOptionsText}
                >
                  <Flex width="100%">
                    <Text ml="16px" fontSize="16px" lineHeight="18px" mr="8px">
                      {option.name}
                    </Text>
                    {option?.iscoming && (
                      <Badge backgroundColor="yellow.500">EM BREVE</Badge>
                    )}
                  </Flex>
                </motion.div>
              </PseudoBox>
              {option.is_parent && (
                <Flex width="100%" flexDirection="column" mt="4px" mb="8px">
                  {option.suboptions.map(suboption => (
                    <PseudoBox
                      display="flex"
                      width="100%"
                      pt="4px"
                      onClick={() => {
                        return history.push(suboption.route);
                      }}
                      _hover={{
                        color: option?.iscoming ? 'white' : 'green.500',
                        cursor: option?.iscoming ? 'default' : 'pointer'
                      }}
                    >
                      <motion.div
                        initial="expanded"
                        animate={isExpandedMenu ? 'expanded' : 'collapsed'}
                        variants={variantsOptionsText}
                      >
                        <Text ml="64px" fontSize="14px" lineHeight="18px">
                          {suboption.name}
                        </Text>
                      </motion.div>
                    </PseudoBox>
                  ))}
                </Flex>
              )}
            </Flex>
          ))}
        </Flex>
        <Flex width="100%" flexDirection="column" py="24px">
          <PseudoBox
            display="flex"
            width="100%"
            onClick={() => handleChangeMenu()}
            alignItems="center"
            cursor="pointer"
            _hover={{
              color: 'green.500'
            }}
          >
            {!isMenuSettings && (
              <>
                <motion.div
                  style={{
                    display: 'flex'
                  }}
                  initial="expanded"
                  animate={isExpandedMenu ? 'expanded' : 'collapsed'}
                  variants={variantsOptions}
                >
                  <FiSettings size={24} />
                </motion.div>

                <motion.div
                  initial="expanded"
                  animate={isExpandedMenu ? 'expanded' : 'collapsed'}
                  variants={variantsOptionsText}
                >
                  <Text ml="16px" fontSize="18px" lineHeight="18px">
                    Configurações
                  </Text>
                </motion.div>
              </>
            )}

            {isMenuSettings && (
              <>
                <motion.div
                  style={{
                    display: 'flex'
                  }}
                  initial="expanded"
                  animate={isExpandedMenu ? 'expanded' : 'collapsed'}
                  variants={variantsOptions}
                >
                  <FiArrowLeft size={24} />
                </motion.div>

                <motion.div
                  initial="expanded"
                  animate={isExpandedMenu ? 'expanded' : 'collapsed'}
                  variants={variantsOptionsText}
                >
                  <Text ml="16px" fontSize="18px" lineHeight="18px">
                    Voltar
                  </Text>
                </motion.div>
              </>
            )}
          </PseudoBox>
        </Flex>
      </motion.div>
    </Flex>
  );
};

export default SideMenu;
