import { motion, Variants } from 'framer-motion';
import React, { createContext, useCallback, useContext, useState } from 'react';

import { Flex, Text } from '@chakra-ui/core';

import Header from '../components/Header';
import SideMenu from '../components/SideMenu';

interface ILayoutContextData {
  isExpandedMenu: boolean;
  title: string;
  collapseOrExpand(value?: boolean): void;
  changeTitle(title: string): void;
}

const LayoutContext = createContext<ILayoutContextData>(
  {} as ILayoutContextData
);

const LayoutDefault: React.FC = ({ children }) => {
  const [isExpandedMenu, setExpandedMenu] = useState(true);
  const [title, setTitle] = useState('Dashboard');

  const variants: Variants = {
    expanded: {
      width: 'calc(100% - 250px)',
      maxWidth: 'calc(100% - 250px)',
      marginLeft: '250px'
    },
    collapsed: {
      width: 'calc(100% - 80px)',
      maxWidth: 'calc(100% - 80px)',
      marginLeft: '80px'
    }
  };

  const collapseOrExpand = useCallback(
    (value = null) => {
      if (value !== null) {
        setExpandedMenu(value);
      } else {
        setExpandedMenu(!isExpandedMenu);
      }
    },
    [isExpandedMenu]
  );

  const changeTitle = useCallback((newTitle: string) => {
    setTitle(newTitle);
  }, []);

  return (
    <LayoutContext.Provider
      value={{ isExpandedMenu, collapseOrExpand, changeTitle, title }}
    >
      <Flex
        width="100%"
        maxWidth="100%"
        height="100vh"
        fontWeight="500"
        position="relative"
        flexWrap="wrap"
      >
        <SideMenu />
        <Flex minWidth="100%" flexDirection="column" backgroundColor="gray.100">
          <motion.div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
            initial="expanded"
            animate={isExpandedMenu ? 'expanded' : 'collapsed'}
            variants={variants}
          >
            <Flex maxWidth="100%" flexDirection="column">
              <Header />
              <Flex width="100%" px="24px" py="24px" mt="48px">
                {children}
              </Flex>
            </Flex>

            <Flex
              width="100%"
              backgroundColor="white"
              justifyContent="center"
              py="4px"
              fontSize="12px"
              mb="0px"
              align="bottom"
            >
              <Flex flexDirection="row">
                <Text mr="4px">Copyright © W2P Solution.</Text>
                <Text color="pink.500" mr="4px">
                  e-Florista
                </Text>
                <Text>todos os direitos reservados .</Text>
              </Flex>
            </Flex>
          </motion.div>
        </Flex>
      </Flex>
    </LayoutContext.Provider>
  );
};

export const useLayout = (): ILayoutContextData => {
  const context = useContext(LayoutContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
};

export default LayoutDefault;
