import React, { createContext, useCallback, useState, useContext } from 'react';

import apiGateway from '../services/apiGateway';

interface IUser {
  id: string;
  name: string;
  email: string;
  avatar_url: string;
}

interface IAuthState {
  token: string;
  user: IUser;
  branch: string;
}

interface ISignInCredentials {
  email: string;
  password: string;
}

interface IAuthContextData {
  user: IUser;
  branch: string;
  signIn(credentials: ISignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: IUser): void;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<IAuthState>(() => {
    const token = localStorage.getItem('@eflorista:token');
    const user = localStorage.getItem('@eflorista:user');
    const branch = localStorage.getItem('@eflorista:branch');

    if (token && user) {
      apiGateway.defaults.headers.authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user), branch };
    }

    return {} as IAuthState;
  });

  const signOut = useCallback(() => {
    localStorage.removeItem('@eflorista:token');
    localStorage.removeItem('@eflorista:user');

    setData({} as IAuthState);
  }, []);

  const signIn = useCallback(async ({ email, password }) => {
    const response = await apiGateway.post('/users/auth', {
      email,
      password
    });

    const { token, user, branch } = response.data;

    localStorage.setItem('@eflorista:token', token);
    localStorage.setItem('@eflorista:user', JSON.stringify(user));
    localStorage.setItem('@eflorista:branch', branch);

    apiGateway.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, user, branch });
  }, []);

  const updateUser = useCallback(
    (user: IUser) => {
      localStorage.setItem('@eflorista:user', JSON.stringify(user));

      setData({
        token: data.token,
        user,
        branch: data.branch
      });
    },
    [data]
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        branch: data.branch,
        signIn,
        signOut,
        updateUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContextData {
  const context = useContext(AuthContext);

  return context;
}

export { AuthProvider, useAuth };
